import React from 'react';
import { AvailablePCsType, ChannelState, RocItem } from '../types';
import { ItemStatus } from '../../project/enums';
import { NodeType, RocItemType } from '../enums';
import { getLists, isInputItem } from '../helpers';
import { useReportAccess } from '../../auth/hooks';
import { useReportContext } from './ReportContext';
import { hasFlag } from '../../core/helpers';
import { rocItemInputs } from '../constants';
import { Channel, HelpMessage, InterviewModel, ReasonBase, Workflow } from '../../core/types/api';
import { CustomSaqStatus } from '../../customSaq/enums';
import { extractPaymentChannelIds } from '../rocItems/TableCell';
import { useAPI } from '../../core/hooks';

type ItemContextType = Partial<RocItem> & {
	lists: {
		workflows: Workflow[];
		interviews: InterviewModel[];
		hints: HelpMessage[];
		allChannelStates: AvailablePCsType;
		applicableChannels: Channel[];
		availablePaymentChannels: Channel[];
		saqOverridePcIds: ChannelState[];
		notApplicableReasons: ReasonBase[];
	};
	isItemApproved?: boolean;
	overrideSaqPC?: (arg?: string, isOverride?: boolean, naMessageOverride?: string) => void;
	isSaqNaOverride: boolean;
	updateItemContextReasons?: (reasons: []) => void;
	isItemAvailable?: boolean;
	displayReasonsBox?: boolean;
	itemProperties: {
		isProjectItem: boolean;
		isProjectInput: boolean;
		isSummary: boolean;
		isAttendeesList: boolean;
	};
};

const itemContextInitial: ItemContextType = {
	lists: {
		workflows: [],
		interviews: [],
		hints: [],
		allChannelStates: [],
		applicableChannels: [],
		availablePaymentChannels: [],
		saqOverridePcIds: [],
		notApplicableReasons: [],
	},
	overrideSaqPC: () => undefined,
	updateItemContextReasons: () => undefined,
	isSaqNaOverride: false,
	itemProperties: {
		isAttendeesList: false,
		isProjectInput: false,
		isProjectItem: false,
		isSummary: false,
	},
};

const ItemContext = React.createContext<ItemContextType>(itemContextInitial);

//General item context

type ItemContextWrapperProps = Partial<
	Omit<ItemContextType, 'children'> & {
		children: Partial<RocItem>;
		saqOverridePcIds: string[] | null;
	}
>;

function ItemContextWrapper({
	itemId,
	useDifferentValuesPerPaymentChannels = false,
	status,
	rocWarnings,
	hiddenWarnings,
	saq,
	children,
	type,
	isAutoGenerated,
}: ItemContextWrapperProps) {
	//Controls context to avoid passing these through all replicator props
	const [context, setContext] = React.useState<ItemContextType>(itemContextInitial);

	const { workflows = [] } = useReportContext();

	React.useEffect(() => {
		setContext({
			...itemContextInitial,
			itemId,
			useDifferentValuesPerPaymentChannels,
			status,
			isItemApproved: status === ItemStatus.Approved,
			saq,
			lists: {
				workflows: workflows.filter(({ itemId: iid }) => itemId === iid) || [],
				interviews: [],
				hints: [],
				allChannelStates: [],
				applicableChannels: [],
				availablePaymentChannels: [],
				saqOverridePcIds: [],
				notApplicableReasons: [],
			},
			isItemAvailable: true,
			isAutoGenerated,
		});
	}, [
		setContext,
		useDifferentValuesPerPaymentChannels,
		status,
		rocWarnings,
		hiddenWarnings,
		saq,
		type,
		isAutoGenerated,
		workflows,
		itemId,
	]);

	return <ItemContext.Provider value={context}>{children as any}</ItemContext.Provider>;
}

//Input item context

function InputItemContextWrapper({
	itemId,
	type,
	nodeType,
	saq,
	modelType,
	data = [],
	summaryId,
	useDifferentValuesPerPaymentChannels,
	status,
	rocWarnings,
	hiddenWarnings,
	children,
	rocDataType,
	attachmentPrefix,
	notApplicableReasons,
	saqOverridePcIds,
}: ItemContextWrapperProps) {
	//Context

	const { paymentChannels, customSaqList } = useReportAccess();
	const { workflows, interviews = [], hints = [], projectId } = useReportContext();

	const { isProjectItem, isProjectInput, isSummary, isAttendeesList } = React.useMemo(() => {
		const isProjectEditableItem =
			!hasFlag(nodeType, NodeType.Template) &&
			!hasFlag(nodeType, NodeType.Ephemeral) &&
			type !== RocItemType.SummaryOfFindingsState;

		return {
			isProjectItem: isProjectEditableItem,
			isProjectInput: isProjectEditableItem && rocItemInputs.indexOf(type || 0) >= 0,
			isSummary:
				type === RocItemType.SummaryOfAssessmentFindingsState || type === RocItemType.Requirement,
			isAttendeesList: type === RocItemType.InterviewAttendee,
		};
	}, [type, nodeType]);

	const { workflowsList, channelStates, hasSaqValue } = React.useMemo(
		() =>
			getLists({
				workflows,
				itemId,
				paymentChannels,
				saq,
				data,
				customSaqList,
				summaryId,
				isSummary,
			}),
		[workflows, itemId, paymentChannels, saq, data, customSaqList, summaryId, isSummary],
	);

	const pcHavingDataIds = React.useMemo(() => extractPaymentChannelIds(data), [data]);

	const [naReasons, setNaReasons] = React.useState(notApplicableReasons || []);
	const updateItemContextReasons = React.useCallback(
		(reasons: []) => {
			setNaReasons(reasons);
		},
		[setNaReasons],
	);

	//SAQ
	const [saqOverrideChannelIds, setSaqOverrideChannelIds] = React.useState<AvailablePCsType>(
		saqOverridePcIds
			? saqOverridePcIds.map((cid) => ({ cid, status: CustomSaqStatus.Applicable }))
			: [],
	);
	const [isSaqNaOverride, setIsSaqNaOverride] = React.useState(false);

	//Payment Channel IDs
	const { isItemAvailable, allChannelStates } = React.useMemo(() => {
		const states: any[] = [
			...saqOverrideChannelIds,
			...channelStates,
			...pcHavingDataIds.map((cid) => ({ cid, status: CustomSaqStatus.Applicable })),
		].map((c) => ({ cid: c.cid, status: String(c.status) }));
		const applicableChannels = states.filter((p) => p.status === CustomSaqStatus.Applicable);
		return {
			isItemAvailable: !hasSaqValue || applicableChannels.length > 0 || workflowsList.length > 0,
			allChannelStates: states,
		};
	}, [saqOverrideChannelIds, channelStates, pcHavingDataIds, hasSaqValue, workflowsList.length]);

	const applicableChannels = React.useMemo(() => {
		if (!useDifferentValuesPerPaymentChannels) return paymentChannels ?? [];
		return (
			paymentChannels?.filter((p) => {
				const { basedOn = 0, customSaqId } = p.saq || {};
				return (
					pcHavingDataIds.indexOf(p.id) < 0 && //No data provided
					(allChannelStates.some(
						(c) => c.cid === p.id && c.status === CustomSaqStatus.Applicable,
					) ||
						saqOverrideChannelIds.filter((c) => c.cid === p.id).length > 0 || //SAQ override
						(!basedOn && !customSaqId))
				);
			}) ?? []
		);
	}, [
		paymentChannels,
		allChannelStates,
		pcHavingDataIds,
		saqOverrideChannelIds,
		useDifferentValuesPerPaymentChannels,
	]);

	const availablePaymentChannels = React.useMemo(
		() =>
			paymentChannels?.filter((p) => {
				const { basedOn = 0, customSaqId } = p.saq || {};
				return (
					(!useDifferentValuesPerPaymentChannels && !isSummary) ||
					((!naReasons || //channel doesnt' mark as NA
						(naReasons.filter((r) => r.paymentChannelId == null).length < 1 &&
							naReasons.filter((r) => r.paymentChannelId === p.id && r.reason?.length > 0).length <
								1)) &&
						(pcHavingDataIds.indexOf(p.id) >= 0 || //might have already some data on the channel
							allChannelStates.some(
								(c) => c.cid === p.id && c.status === CustomSaqStatus.Applicable,
							) ||
							saqOverrideChannelIds.filter((c) => c.cid === p.id).length > 0 || //SAQ override
							(isSummary && saqOverrideChannelIds.filter((c) => !c.cid).length > 0) || //SAQ All channels override
							(!basedOn && !customSaqId)))
				);
			}) ?? [],
		[
			useDifferentValuesPerPaymentChannels,
			paymentChannels,
			isSummary,
			allChannelStates,
			naReasons,
			saqOverrideChannelIds,
			pcHavingDataIds,
		],
	);

	const { fetchAPI } = useAPI();

	const overrideSaqPC = React.useCallback(
		(pcId?: string, isOverride?: boolean, naMessageOverride?: string) => {
			fetchAPI({
				query: `RocItemControllerNew/SaqOverride/${projectId}/${itemId}`,
				method: 'POST',
				params: {
					PaymentChannelId: pcId,
					IsOverride: isOverride || false,
					NaMessageOverride: naMessageOverride,
				},
				onSuccess: () => {
					if (naMessageOverride) {
						setNaReasons((prevState) => {
							const newState: any = [...(prevState || [])];
							newState.push({
								reason: naMessageOverride,
								paymentChannelId: pcId,
							});
							return newState;
						});
						setIsSaqNaOverride(true);
					}

					setSaqOverrideChannelIds((prevState) => {
						if (isOverride && prevState.map((c) => c.cid).indexOf(pcId) >= 0) return prevState;
						if (!isOverride && prevState.map((c) => c.cid).indexOf(pcId) < 0) return prevState;
						if (isOverride)
							return [...prevState, { cid: pcId, status: CustomSaqStatus.Applicable }];

						const newState = [...(prevState || [])];
						newState.splice(prevState.map((c) => c.cid).indexOf(pcId), 1);
						return newState;
					});
				},
			});
		},
		[fetchAPI, projectId, itemId, setSaqOverrideChannelIds],
	);

	//State

	const [context, setContext] = React.useState<ItemContextType>(itemContextInitial);

	React.useEffect(() => {
		setContext({
			...itemContextInitial,
			itemId,
			useDifferentValuesPerPaymentChannels,
			status,
			isItemApproved: status === ItemStatus.Approved,
			rocWarnings,
			hiddenWarnings,
			saq,
			modelType,
			lists: {
				workflows: workflowsList,
				interviews: interviews.filter(
					({ rocItemsIds }) => rocItemsIds && rocItemsIds.indexOf(itemId) >= 0,
				),
				hints: hints.filter(({ rocItemId }) => rocItemId === itemId),
				allChannelStates,
				applicableChannels,
				availablePaymentChannels,
				saqOverridePcIds: saqOverrideChannelIds,
				notApplicableReasons: naReasons,
			},
			overrideSaqPC,
			updateItemContextReasons,
			isSaqNaOverride,
			isItemAvailable,
			displayReasonsBox:
				(isSummary || useDifferentValuesPerPaymentChannels) &&
				(allChannelStates.filter((c) => c.status !== CustomSaqStatus.Applicable).length > 0 ||
					saqOverrideChannelIds.length > 0),
			itemProperties: {
				isSummary,
				isProjectItem,
				isProjectInput,
				isAttendeesList,
			},
			rocDataType,
			attachmentPrefix,
		});
	}, [
		setContext,
		itemId,
		isSaqNaOverride,
		saqOverrideChannelIds,
		naReasons,
		useDifferentValuesPerPaymentChannels,
		status,
		rocWarnings,
		hiddenWarnings,
		saq,
		workflows,
		type,
		overrideSaqPC,
		updateItemContextReasons,
		isItemAvailable,
		isSummary,
		isProjectItem,
		isProjectInput,
		isAttendeesList,
		workflowsList,
		rocDataType,
		interviews,
		attachmentPrefix,
		modelType,
		hints,
		allChannelStates,
		applicableChannels,
		availablePaymentChannels,
		notApplicableReasons,
	]);

	return <ItemContext.Provider value={context}>{children as any}</ItemContext.Provider>;
}

//Non-input items don't need some calculations, so those are avoided using conditional context

export function TypeItemContextWrapper({ type, children, ...rest }: ItemContextWrapperProps) {
	if (type === undefined) return null;

	if (!isInputItem(type))
		return (
			<ItemContextWrapper type={type} {...rest}>
				{children}
			</ItemContextWrapper>
		);

	return (
		<InputItemContextWrapper type={type} {...rest}>
			{children}
		</InputItemContextWrapper>
	);
}

export function useItemContext(): ItemContextType {
	return React.useContext(ItemContext);
}
